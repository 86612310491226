import React, { useState, useEffect, useRef } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { hasLink } from '../../util/Utils';

const Carousel = (props) => {


  const { image_radius = 0, auto = false, timeout = 3, images = [], animation = "fade", button_type = 1, height = 400, background_size = "cover", background_position = "center center" } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [hide, setHide] = useState("next")


  const getNeighbours = (_index, _array) => {

    const length = 3;
    const prev = (_index - 1 + length) % length;
    const next = (_index + 1) % length;

    return {
      prev: prev,
      next: next
    };
  }




  const handleNext = () => {
    setHide("next")
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);

  };

  const handlePrevious = () => {
    setHide("previous")
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };


  useEffect(() => {

    const intervalId = setInterval(() => {
      if (auto) {
        handleNext()
      }

    }, (timeout * 1000));
    return () => clearInterval(intervalId);
  }, [timeout, auto]);



  const getClass = (index) => {

    if (index === currentImageIndex) {
      return "carousel-image active"
    }

    const n = getNeighbours(currentImageIndex);


    if (hide === "next" && index === n.next && currentImageIndex === 1) {
      return "carousel-image hide"
    }

    if (hide === "prev" && index === n.prev && currentImageIndex !== 1) {
      return "carousel-image hide"
    }



    if (index === n.next) {
      return "carousel-image next"
    }


    if (index === n.prev) {
      return "carousel-image previous"
    }

    return "carousel-image hide";


  }


  const openURL = (image) => {
    if (hasLink(image.link)) {
      window.open(image.link, (image.tab) ? "_blank" : "_self");
    }
  }

  const parsePath = (url) => {
    const abs = /^(?:[a-z]+:)?\/\//i;
    return (abs.test(url)) ? url : "https://cdn1.bookmanager.com/i/" + window.san + url;
  }

  return (

    <div style={{ "height": height + "px", "position": "relative" }} className={`carousel ${animation + "-transition"}`}>

      <div style={{ "borderRadius": image_radius + "px" }} className="carousel-image-container">
        {images.map((image, index) => (
          <img
            tabIndex={((hasLink(image.link) && currentImageIndex === index) ? 0 : null)}
            style={{ objectFit: background_size, objectPosition: background_position, "cursor": (image.link) ? "pointer" : "initial", "zIndex": (index === currentImageIndex) ? "1" : "0" }}
            key={index}
            onKeyDown={(e) => { if (e.key === "Enter") { openURL(image) } }}
            onClick={() => openURL(image)}
            src={parsePath(image.path)}
            aria-label={(!hasLink(image.link)) ? image.alt : (image.tab) ? "Visit link (opens in a new tab)" : "Visit link"}
            alt={image.alt}
            className={getClass(index)}

          />
        ))}
      </div>


      <Button className={"bm-btn-" + button_type} onClick={() => handlePrevious()} style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', "zIndex": "2" }}>
        <LeftOutlined style={{ "minWidth": "20px" }} />
      </Button>

      <Button className={"bm-btn-" + button_type} onClick={() => handleNext()} style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', "zIndex": "2" }} >
        <RightOutlined style={{ "minWidth": "20px" }} />
      </Button>


    </div>
  );
};


export default Carousel;