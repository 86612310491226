import { Button, Card, Col, Divider, Image, Layout, message, Modal, Row, Skeleton, Space, Tabs, Tooltip, Typography } from 'antd';
import moment from "moment";
// Resources
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import ReactPlayer from 'react-player/youtube'
import { Link, useHistory } from "react-router-dom";

import { useSession } from "../../../util/Session";
import { extractValueFromUrl, getItemAlt, parseRank, parseSubjects, parseSubjectsList } from "../../../util/Utils"
// Components
import AddToCart from "../../AddToCart";
import { apiCall } from "../../utilities/Api";
import conditional from "../../utilities/conditional";
import Html from "../../utilities/Html";
import Audiobook from "./Audiobook";
import Authors from "./Authors";
import Bindings from "./Bindings"
import Comments from "./Comments";
import IncludedIn from "./IncludedIn"
import ItemImageMobileGallery from "./ItemImageMobileGallery";
import ItemImageThumbnailGallery from "./ItemImageThumbnailGallery";
import MoreSizes from "./MoreSizes"
import Related from "./Related";
import SalesIndicator from "./SalesIndicator";
import Series from "./Series"

//Deconstruct
const { Text } = Typography;
const { TabPane } = Tabs;



function ItemDetails(props) {

    const history = useHistory();
    const [session, setSession] = useSession();
    const [initLoaded, setInitLoaded] = useState(false);
    const { data } = props;
    const {
        reviews = [],
        comments = [],
        rank = [],
        subjects = [],
        bio = [],
        title = "",
        subtitle = "",
        language = "",
        description = "...",
        publisher = "",
        edition = "",
        eisbn = "",
        cover_image_cache = "",
        isbn = "",
        isbn_type = "",
        upc = "",
        upc_type = "",
        series = "",
        series_num = "",
        binding = "",
        pages = "",
        width = "",
        height = "",
        weight = "",
        carton_qty = "",
        media = [{ data: [], type: "" }],
        release_date = null,
        is_forthcoming = false,
        has_libro_audiobook = false,
        list_previous_eisbn = "",
        list_next_eisbn = "",
        audience = "",
        interior_objects = [],
        is_giftcard = false,
        last_order = {
            bookorder_id: null,
            date_ordered: null
        }
    } = data;

    const recaptchaRef = useRef();

    const _isbn_type = (isbn_type === "ean") ? "" : isbn_type;
    if (is_giftcard) {
        history.push("/gift-cards");
    }
    const list_id = extractValueFromUrl(window.location.pathname, "lists") || "";
    const [copied, setCopied] = useState(false);
    const [related, setRelated] = useState({});
    const copyToClipboard = (_txt) => {
        setCopied(true);
        navigator.clipboard.writeText(_txt);
        setTimeout(() => {
            setCopied(false)
        }, 1000);
    }

    const [fullISBN, setFullISBN] = useState("");

    useEffect(() => { setFullISBN("") }, [eisbn])

    const onGetRelatedItems = (_status, _result) => {
        if (_status) {
            setRelated(_result.related);
        }
        setInitLoaded(true);
    }

    const getRelatedItems = () => {
        apiCall("title/getRelatedItems", { eisbn: eisbn }, onGetRelatedItems)
    }

    useEffect(getRelatedItems, [])
    useEffect(getRelatedItems, [eisbn])
    const getProductCode = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (fullISBN) {
            return;
        }

        if (!session.logged_in) {
            recaptchaRef.current.reset();
            recaptchaRef.current.execute();
            return;
        }

        apiCall("title/getProductCode", { eisbn: eisbn }, (_status, _result) => {
            if (_status) {
                setFullISBN(_result.isbn);
            } else {
                Modal.error({ title: "Error", content: _result.error })
            }

        })
    }


    const onChange = (e) => {

        // don't run if captcha is null or already run
        if (!e || fullISBN) {
            return;
        }

        apiCall("title/getProductCode", { eisbn: eisbn, captcha: e }, (_status, _result) => {
            if (_status) {
                setFullISBN(_result.isbn);
            } else {
                Modal.error({ title: "Error", content: _result.error })
            }
        })

    }

    const drawNext = () => {
        if (list_next_eisbn) {
            let url = "/item/" + list_next_eisbn + "/lists/" + list_id;
            return (<Button onClick={() => history.push(url)}>Next</Button>)
        } else {
            return (<></>);
        }
    }

    const drawPrev = () => {
        if (list_previous_eisbn) {
            let url = "/item/" + list_previous_eisbn + "/lists/" + list_id;
            return (<Button onClick={() => history.push(url)}>Previous</Button>)
        } else {
            return (<></>);
        }
    }

    const drawBack = () => {
        if (list_previous_eisbn || list_next_eisbn) {
            let url = "/lists/" + list_id;
            return (<Button onClick={() => history.push(url)}>Go back</Button>)
        } else {
            return (<Button onClick={history.goBack}>Go back</Button>);
        }
    }

    const drawFullIsbn = () => {
        return (<span><Button type="primary" onClick={() => copyToClipboard(fullISBN)} size="small"><small>{(copied) ? "Copied to clipboard" : "Copy"}</small></Button> &nbsp;<span onClick={() => copyToClipboard(fullISBN)}>{_isbn_type}: {fullISBN}</span> </span>)
    }

    const parseBio = (_bios) => {
        return _bios.map((_bio, index) => {
            return (<Html className="" key={index} html={_bio} />);
        });
    }

    const parseReviews = (_reviews) => {
        return _reviews.map((_review, index) => {
            return (<span key={index}><Html className="" html={_review.content} />
                <div style={{ "textAlign": "right", "display": "block" }}><small>
                    {(_review.author && <em><Html className="" html={_review.author} /></em>)}
                    {(_review.source_title && <em><Html className="" html={_review.source_title} /></em>)}
                </small>
                </div>
                <div aria-hidden><Divider /></div></span>);
        });
    }

    const displayDetails = () => {
        return (
            <div className="details-box">
                <conditional.string value={binding}>
                    <div key="1"><strong>{binding} </strong> {(pages) ? pages + " pages" : ""}</div>
                </conditional.string>
                
                <conditional.string value={series}>
                    <div key="2"><strong>Series: </strong> {series} {(series_num) ? "#" + series_num : ""}</div>
                </conditional.string>
                <conditional.string value={publisher}>
                    <div key="3"><strong>Publisher: </strong> {publisher} </div>
                </conditional.string>
                <conditional.string value={edition}>
                    <div key="3"><strong>Edition: </strong> {edition} </div>
                </conditional.string>
                <conditional.true value={(language)}>
                    <div key="12"><strong>Language: </strong> {language} </div>
                </conditional.true>
                <conditional.true value={release_date}>
                    <div key="4"><strong>{(is_forthcoming) ? "Releases:" : "Published:"} </strong> {moment(release_date * 1000).format("MMMM D, YYYY")} </div>
                </conditional.true>
                <conditional.true value={audience}>
                    <div key="5"><strong>Audience: </strong> {audience} </div>
                </conditional.true>
                <conditional.array value={subjects}>
                    <div key="6"><strong>Subjects: </strong><span style={{ "display": "inline", "padding": "10px 0px" }}>{parseSubjectsList(subjects)}</span></div>
                </conditional.array>
                <conditional.string value={(isbn || upc)}>
                    <div key="7"><strong>EAN: </strong> <span>{(upc) ? <>{upc_type}: {upc}</> : ""} {(isbn && upc && <> - </>)} {(isbn) ? <span onClick={(e) => getProductCode(e)} style={{ "cursor": "pointer" }}><a> {(fullISBN) ? drawFullIsbn() : <>

                        <Button style={{ "width": "100px" }} onClick={(e) => getProductCode(e)} size="small" type="primary">
                            <small>Show all digits</small>
                        </Button>&nbsp; {_isbn_type}: {data.isbn}</>}</a></span> : ""}  </span>
                    </div>


                </conditional.string>
                <conditional.true value={width}>
                    <div key="8"><strong>Physical Dimensions: </strong> {width}" x {height}" </div>
                </conditional.true>
                <conditional.true value={weight}>
                    <div key="9"><strong>Shipping Weight: </strong> {(weight && weight.toString())} lbs.</div>
                </conditional.true>
                <conditional.true value={carton_qty}>
                    <div key="10"><strong>Carton Quantity: </strong> {(carton_qty && carton_qty.toString())}</div>
                </conditional.true>
                <conditional.true value={rank}>
                    <conditional.true value={(!window.sitesettings.hide_sales_rank && rank)}>
                        <div key="11"> <strong>Sales Rank: </strong> {parseRank(rank)} </div>
                    </conditional.true>
                </conditional.true>
                <span style={{ "display": "block", "textAlign": "right", "borderTop": "1px solid #eee" }} key="12">
                    <span className='shim' /><span className='shim' /><span className='shim' /><span className='shim' />
                    <span>
                        <span style={{ display: "block" }}>This site is protected by reCAPTCHA and the Google</span>
                        <span style={{ display: "block" }}><a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</span>
                    </span>
                </span>

                <Related related={related} />
            </div>
        )
    }

    const [showMoreSizes, setShowMoreSizes] = useState();

    const grabMoreSizes = () => {

        apiCall("title/getMoreSizes", { eisbn: data.eisbn }, (_status, _result) => {
            if (_status) {
                if (_result.related[0].rows.length > 0) {
                    setShowMoreSizes(true);
                }
            }
        })
    }

    useEffect(grabMoreSizes, []);

    const showLoading = () => {
        if (!props.loading) {
            return (<></>);
        } else {
            return (<Skeleton active />)
        }
    }

    function grabYouTubeIDs() {
        let media_urls = "";
        for (let i = 0; i < media.length; i++) {
            if (media[i].type === "component") {
                media_urls = media[i].data
            }
        }
        return media_urls;
    }

    function grabMediaURLs() {
        let media_urls = "";
        for (let i = 0; i < media.length; i++) {
            if (media[i].type === "url") {
                media_urls = media[i].data
            }
        }
        return media_urls;
    }

    function displayVideos() {
        let youtube_ids = grabYouTubeIDs();
        let display_urls = grabMediaURLs();
        if (display_urls.length > 0 || youtube_ids.length > 0) {
            return (<>
                {/* See this for more info on ReactPlayer:
                https://github.com/CookPete/react-player */}
                {youtube_ids.map((item) => {
                    return (
                        <div style={{ "marginBottom": "10px" }} className='player-wrapper'>
                            <ReactPlayer
                                className='react-player'
                                url={'https://www.youtube.com/watch?v=' + item}
                                controls={true}
                                width="100%"
                                height="100%" />
                        </div>)
                })}
                {display_urls.map((item) => {
                    return (
                        <div style={{ "marginBottom": "10px" }} className='player-wrapper'>
                            <ReactPlayer
                                className='react-player'
                                url={item}
                                controls={true}
                                width="100%"
                                height="100%" />
                        </div>)
                })}
            </>)
        }
    }
    console.log(related)

    const displayExtraDetails = (draw_libro = false) => {
        return (
            <>
                <conditional.true value={showMoreSizes}>
                    <MoreSizes data={data} />
                </conditional.true>
                <conditional.true value={!showMoreSizes}>
                    <Bindings data={data} />
                </conditional.true>
                <div className="show">
                    <div className="shim" />
                    <div className="shim" />
                    <div className="shim" />
                    <div className="shim" />
                    <AddToCart data={data} />
                </div>
                {(draw_libro && <>
                    {drawLibro()}
                </>)}
                <conditional.string value={series}>
                    <div className="shim" />
                    <Series data={data} />
                </conditional.string>
                <conditional.array value={comments}>
                    <Comments data={data} />
                </conditional.array>
                <conditional.true value={related.included_in.row_count > 0 || related.included_items.row_count > 0}>
                    <IncludedIn data={related} title={title} />
                </conditional.true>

                {showLoading()}
                <div aria-hidden><Divider /></div>
                <Tabs >
                    {((description) &&
                        <TabPane tab="Description" key="1">
                            <Text><Html className="" html={description} /><div aria-hidden><Divider /></div></Text>
                        </TabPane>
                    )}
                    {(bio.length > 0 &&
                        <TabPane tab="Bio" key="2">
                            <Text>{parseBio(bio)}<div aria-hidden><Divider /></div></Text>
                        </TabPane>
                    )}
                    <TabPane tab="Details" key="3">
                        <Text>{displayDetails()}<div aria-hidden><Divider /></div></Text>
                    </TabPane>
                    {((media[0].data.length > 0 && (media[0].data.length > 0 || media[1].data.length > 0)) &&
                        (<TabPane tab="Videos" key="4">
                            <Text>{displayVideos()}</Text>
                        </TabPane>
                        ))}
                    {(reviews.length > 0 && (
                        <TabPane tab="Reviews" key="5">
                            <Text>{parseReviews(reviews)}</Text>
                        </TabPane>
                    ))}
                </Tabs>
                {/* <conditional.true value={has_libro_audiobook}>
                    <Audiobook data={data} />
                </conditional.true> */}
            </>
        )
    }


    const drawSubtitle = () => {
        let _ret = ""
        if (series) {
            if (series_num) {
                _ret = series + " #" + series_num;
            } else {
                _ret = series;
            }
        }
        if (subtitle && _ret) {
            return (<>{subtitle}<div><small>{_ret}</small></div></>);
        } else {
            return subtitle;
        }
    }


    const drawLibro = () => {
        return (
            <conditional.true value={(has_libro_audiobook && window.sitesettings.store_info.prominent_librofm)}>
                <>
                    <br />
                    <Card className='asa-card' bodyStyle={{ "padding": "10px", "cursor": "pointer" }}>
                        <Audiobook eisbn={eisbn}>
                            <div style={{ "padding": "0px", "textAlign": "center" }}>
                                <div>Buy digital audiobook</div>
                            </div>
                            <div className="shim" />
                            <div style={{ "textAlign": "center" }}>
                                <img alt="Libro FM" style={{ "width": "150px", "padding": "0px", "textAlign": "center" }} src="https://cdn1.bookmanager.com/i/librofm_logo.png" />
                            </div>
                            <div className="shim"></div>
                        </Audiobook>
                    </Card>
                </>
            </conditional.true>
        )
    }


    return (
        (initLoaded && <div>
            <conditional.true value={(last_order.date_ordered && session.logged_in)}>
                <div><span style={{ "display": "block", "color": "#e70d3d", "fontSize": "16px" }}><em>You purchased this item on {moment(last_order.date_ordered * 1000).format("MMMM Do, YYYY")}.</em></span>
                    <conditional.true value={(last_order.bookorder_id && session.logged_in)}>
                        <Link to={"/account/order/" + last_order.bookorder_id}><span className='asa-author' style={{ "display": "inline-block", "color": "#069" }}>View previous order</span></Link>
                    </conditional.true>
                </div>
            </conditional.true>
            <div className="shim hide-block"></div>
            <div className="shim hide-block"></div>
            <div className="shim"></div>
            <Layout className="responsiveSider">
                <Layout.Content style={{ "width": "auto" }}>
                    <Row gutter={10}>
                        <Col xs={{ span: 24, order: 1 }} lg={{ span: 8, order: 1 }}>
                            <div className="show">
                                <div style={{ "float": "right" }}>
                                    <Space>
                                        {drawPrev()}
                                        {drawNext()}
                                        {drawBack()}
                                    </Space>
                                </div>
                                <br clear="all" />
                                <div aria-hidden><Divider className="show-block" style={{ "marginBottom": "5px" }} /></div>
                                <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                            </div>
                            {/* Desktop Image Gallery */}
                            <div className="hide">
                                <Space align="start">
                                    <div style={{ "height": "410px" }}><ItemImageThumbnailGallery data={data} /> </div>
                                    <Image
                                        tabIndex={0}
                                        style={{ "cursor": "pointer" }}
                                        alt={getItemAlt(data)}
                                        className="shadow-light full-image"
                                        fallback={"https://cdn1.bookmanager.com/i/nocover.png"}
                                        src={"https://cdn1.bookmanager.com/i/m?b=" + eisbn + "&cb=" + cover_image_cache}
                                    />
                                    {/* <img alt={title} onError={(i) => { i.target.src = "https://bookmanager.com/i/nocover.png"; i.target.className = "light"; }} className="shadow-light full-image" src={"https://bookmanager.com/i/m?b=" + eisbn + "&cb=" + cover_image_cache} /> */}
                                </Space>
                            </div>
                            {/* <ItemImageGallery data={data} /> */}
                        </Col>
                        <Col xs={{ span: 24, order: 2 }} lg={{ span: 16, order: 2 }}>
                            <h2 className="hide">{title}<span>{drawSubtitle()}</span></h2>
                            <h3 style={{ "fontSize": "22px", "fontWeight": "bold" }} className="show">{title}<span>{drawSubtitle()}</span>
                            </h3>
                            <Authors data={data} />
                            <conditional.true value={edition}>
                                {(data.edition && <div style={{ "fontSize": "14px", "display": "block", "fontWeight": "normal", "marginLeft" : "-2px" }}><mark>{data.edition}</mark><div className="shim" /></div>)}
                            </conditional.true>
                            <conditional.true value={publisher}>
                                <span style={{ "display": "block" }}><strong>Publisher: </strong>{publisher}</span>
                            </conditional.true>
                            <conditional.true value={(language)}>
                                <span style={{ "display": "block" }}><strong>Language: </strong>{language}</span>
                            </conditional.true>
                            <Text>{parseSubjects(subjects)}</Text>
                            <conditional.true value={(audience)}>
                                <span style={{ "display": "block" }}><strong>{audience}</strong></span>
                            </conditional.true>
                            <div className="shim" />
                            <conditional.true value={(!window.sitesettings.hide_sales_rank)}>
                                <Tooltip mouseEnterDelay={0.5} title={"Based on weekly sales from independent bookstores across the country"}>
                                    <SalesIndicator rank={rank} /></Tooltip>
                                <div className="shim" /><div className="shim" />
                            </conditional.true>
                            {/* Mobile Image Gallery */}
                            <div className="show" style={{ "textAlign": "center" }}>
                                <ItemImageMobileGallery data={data} />
                                <br clear="all" />
                                {(data.has_interiors && <><div className="shim" /><div className="shim" /><div className="shim" /></>)}
                                {/* <img alt={title} onError={(i) => { i.target.src = "https://bookmanager.com/i/nocover.png"; i.target.className = "light"; }} className="shadow-light full-image" src={"https://bookmanager.com/mosaic/i/m?b=" + eisbn} /> */}
                            </div>
                            <div className="hide-block">
                                {displayExtraDetails()}
                            </div>
                        </Col>
                    </Row>
                    <div className="show-block">
                        <div aria-hidden><Divider /></div>
                        {displayExtraDetails(true)}
                    </div>
                </Layout.Content>
                <Layout.Sider theme="light" width="none" className="itemDetailsSider adjust-padding hide" >
                    <div style={{ "float": "right" }}>
                        <Space>
                            {drawPrev()}
                            {drawNext()}
                            {drawBack()}
                        </Space>
                    </div>
                    <br clear="all" />
                    <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                    <Divider className="show-lg" />
                    <div className="shim" />
                    <AddToCart data={data} />
                    {drawLibro()}
                </Layout.Sider>
            </Layout>
            {(!session.logged_in && <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6LfPBUslAAAAAIrLp814HpBIixMj0rL5J3cAxEUw" onChange={onChange} />)}
        </div>)

    )
}

export default ItemDetails;