import React, { useState } from "react";
import { Card, Row, Col, Layout, Divider, Table, Alert, Button, Modal, message, Space } from 'antd';
import { CartSummary } from "./Cart";
import { useSession } from "../../../util/Session";
import { getPaymentLabel } from "./PaymentChoices";
import { useHistory } from "react-router-dom";
import { apiCall } from "../../utilities/Api";
import { PayPalButton } from "react-paypal-button-v2";
import conditional from "../../utilities/conditional";
import PhoneNumberFormatter from "../../formatters/PhoneNumberFormatter";
import ClearentPaymentsForm from "./ClearentPaymentsForm";
import GlobalPaymentsForm from "./GlobalPaymentsForm";
import GlobalPaymentsButton from "./GlobalPaymentsButton";
import BMAlert from "../../../util/BMAlert";
import { getItemAlt } from "../../../util/Utils";
import { pixelTrack } from "../../../util/SiteRouter";

export default function Summary(props) {

    const history = useHistory();
    const { saved } = props;
    const [errorMSG, setErrorMSG] = useState(false);
    const [session] = useSession();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [waitingToProcess, setWaitingToProcess] = useState(false);


    const doubleCheck = (_session) => {
        let _flag = true;
        let _retitems = [];
        _session.cart.items.forEach(item => {
            let _xtra = item.title_info.available.is_extra_orderable;
            if (!_xtra) {
                let _ava = parseInt(item.title_info.available.onhand || 0);
                // if oh is a char they can overbuy... 
                if (!isNaN(_ava)) {
                    let _qty = item.cart_info.quantity;
                    if (_qty > _ava) {
                        _retitems.push(item);
                        _flag = false;
                    }
                }
            }
        });

        return _flag;
    }


    const drawWarning = () => {


        Modal.warn({
            icon: null,
            title: "Cart adjustment",
            okText: "Return to cart",
            onOk: () => {
                history.push("/checkout/cart");
            },
            content: <>
                <p>We no longer have the inventory to fulfill this order.</p>
            </>
        });


    }


    const submitLarge = (_paypal_order_id = false) => {
        if (_paypal_order_id) {
            submit(_paypal_order_id);
        } else {
            apiCall("session/get", {}, (_status, _result) => {
                if (_status) {
                    if (doubleCheck(_result)) {
                        if (_result.cart.contains_unavailable) {
                            drawWarning();
                        } else {
                            submit();
                        }
                    } else {
                        drawWarning();
                    }
                } else {
                    message.error(_result.error);
                }
            })
        }

    }

    let can_submit = true;

    const submit = (_paypal_order_id = false) => {


        // disable submit for a second
        if (!can_submit) {
            setTimeout(() => {
                can_submit = true;
            }, 1000)
            return;
        }
        can_submit = false;
        setSubmitLoading(true);


       


        // Collect args from saved / session
        let obj = {
            delivery_preference: saved.delivery,
            address_id: saved.shipping.hasOwnProperty("address_id") ? saved.shipping.address_id : "",
            provider: saved.provider,
            multiship_method: saved.batch,
            gift_registry_id: saved.shipping.hasOwnProperty("gift_registry_id") ? saved.shipping.gift_registry_id : "",
            preferred_communication: saved.contact.hasOwnProperty("preferred_communication") ? saved.contact.preferred_communication : "",
            old_promo_code: saved.contact.hasOwnProperty("promo_code") ? saved.contact.promo_code : "",
            instructions: saved.contact.hasOwnProperty("instructions") ? saved.contact.instructions : "",
            // name: saved.shipping.hasOwnProperty("name") ? saved.shipping.name : "",
            // email: saved.shipping.hasOwnProperty("email_address") ? saved.shipping.email_address : "",
            // phone: saved.shipping.hasOwnProperty("phone_number") ? saved.shipping.phone_number : "",
            payment_choice: saved.payment,
            encrypted_cc_info: saved.cc_info,
            gift_cards: session.cart.hasOwnProperty("gift_cards") ? JSON.stringify(session.cart.gift_cards) : ""
        }

        // Contact if not logged in

        if (!session.logged_in) {

            if (saved.contact.hasOwnProperty("name")) {
                obj.name = (saved.contact.name) ? saved.contact.name : ""
            }

            // don't set unless there is a value
            if (saved.contact.hasOwnProperty("email")) {
                if (saved.contact.email) {
                    obj.email = saved.contact.email;
                }
            }

            if (saved.contact.hasOwnProperty("phone")) {
                obj.phone = (saved.contact.phone) ? saved.contact.phone : ""
            }

        }

        // Add promo codes
        let _find = session.cart.summary.promo_codes.find(item => !item.automatic);
        if (_find) {
            obj.promo_code = _find.code;
        }

        // remove keys that have no data...
        Object.keys(obj).forEach(key => {
            if (obj[key] === "") {
                delete obj[key];
            }
        })


        // SEND FOR PAYPAL
        if (_paypal_order_id !== false) {
            obj.order_id = _paypal_order_id;
            apiCall("checkout/paypalSuccess", obj, (_status, _result) => {
                if (_status) {
                    let order_number = _result.order_number;
                    history.push({ "pathname": "/account/order/" + order_number, "state": { "data": _result }, "search": "success" });
                } else {
                    Modal.error({
                        title: 'There was an error processing your order.',
                        content: <>No funds were removed from your PayPal account - Please ensure you start and complete your checkout in a single browser tab. </>,
                        okText: "Start Over",
                        onOk: () => history.push("/checkout/cart")
                    });

                    
                }
                setWaitingToProcess(false);
                setSubmitLoading(false);
                can_submit = true;

            })


        } else {
            // SEND REGULAR
            apiCall("checkout/submit", obj, (_status, _result) => {
                if (_status) {

                    pixelTrack("Purchase", session.cart, obj);
                    let order_number = _result.order_number;
                    history.push({ "pathname": "/account/order/" + order_number, "state": { "data": _result }, "search": "success" });
                } else {
                    if (typeof _result.error === 'string') {
                        setErrorMSG(_result.error);
                        message.error(<span aria-live="assertive">{_result.error}</span>)
                    } else {
                        let _str = JSON.stringify(_result.error);
                        setErrorMSG("There was an error: (" + _str + ")");
                        message.error(<span aria-live="assertive">There was an error: {_str}</span>)
                    }
                }
                setSubmitLoading(false);
                can_submit = true;
            });

        }
    }



    const sumTaxes = (_arr) => {
        let ret = 0;
        _arr.forEach(_itm => {
            ret += parseFloat(_itm.amount);
        });
        return ret.toFixed(2);
    }

    const displayPaypalButton = () => {




        return (
            <>
                {/* <pre>{JSON.stringify(test, null, 2)}</pre> */}
                <PayPalButton

                    createOrder={(data, actions) => {

                        // DISCOUNT LOGIC
                        let total_discount = 0;
                        let total_item_total = parseFloat(session.cart.summary.subtotal);

                        if (session.cart.summary.promo_codes.length > 0) {
                            // Add Promo Discount To Total Discount
                            total_discount = parseFloat(session.cart.summary.promo_codes[0].amount);
                            // Add Promo Discount To Total Item

                            // DONT ADD TO THE TOTAL IF IT'S FREE SHIP
                            if (session.cart.summary.promo_codes[0].type !== "freeship") {
                                total_item_total = total_item_total + parseFloat(session.cart.summary.promo_codes[0].amount);
                            }

                        }

                        // Add Giftcard discount to Total Discount
                        total_discount = total_discount + parseFloat(session.cart.summary.post_subtotal_discount);

                        // Convert back to strings
                        total_discount = total_discount.toFixed(2).toString();
                        total_item_total = total_item_total.toFixed(2).toString();

                        return actions.order.create({
                            application_context: {
                                shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                            },
                            purchase_units: [{
                                amount: {
                                    currency_code: window.sitesettings.checkout_info.currency,
                                    value: session.cart.summary.total,
                                    breakdown: {
                                        item_total: { value: total_item_total, currency_code: window.sitesettings.checkout_info.currency },
                                        tax_total: { value: sumTaxes(session.cart.summary.taxes), currency_code: window.sitesettings.checkout_info.currency },
                                        shipping: { value: session.cart.summary.shipping, currency_code: window.sitesettings.checkout_info.currency },
                                        discount: { value: total_discount, currency_code: window.sitesettings.checkout_info.currency }
                                    }
                                },
                                items: session.cart.items.map(item => {
                                    return {
                                        name: item.title_info.title,
                                        // unit_amount: { value: item.title_info.available.row_price, currency_code: window.sitesettings.checkout_info.currency },
                                        unit_amount: { value: item.cart_info.sold_price, currency_code: window.sitesettings.checkout_info.currency },
                                        quantity: item.cart_info.quantity,
                                    }
                                })
                            }],
                        });
                    }}

                    amount={session.cart.summary.total}
                    shippingPreference="NO_SHIPPING"

                    style={{
                        shape: 'rect',
                        color: 'gold',
                        layout: 'horizontal',
                        label: 'paypal',
                    }}

                    onError={(error) => {
                        let err = {};
                        err.location = history.location.pathname;
                        err.user_agent = (navigator && navigator.userAgent) ? navigator.userAgent : "no agent";
                        err.error_info = error;
                        err.source = "no source";
                        err.lineno = "no lineno";
                        err.colno = "no colno";
                        err.error = error.name;
                        apiCall("log/error", err, () => { });
                    }}

                    onApprove={(data, actions) => {
                        setWaitingToProcess(true);
                        return submitLarge(data.orderID)
                    }}

                    options={{
                        currency: window.sitesettings.checkout_info.currency,
                        clientId: window.sitesettings.checkout_info.paypal_id
                    }}

                />
            </>
        )

    }

    const getDeliveryLabel = (_key) => {
        let ret = "";
        switch (_key) {
            case "pickup":
                ret = "Pick up in store"
                break;
            case "ship":
                ret = "Ship"
                break;
            case "electronic":
                ret = "Email"
                break;
            default:
                ret = "Pick up in store"
                break;
        }

        return ret;
    }

    const ucFirst = (_str) => {
        if (_str) {
            return _str.charAt(0).toUpperCase() + _str.slice(1);
        } else {
            return "";
        }
    }

    const drawDeliveryPreference = () => {


        if(saved.delivery === "pickup" && saved?.custom === true && window.sitesettings.checkout_info.label_override_pickup){
            return(<strong>{window.sitesettings.checkout_info.label_custom_pickup}</strong>)
        }

        if(saved.delivery === "ship" && saved?.custom === true && window.sitesettings.checkout_info.label_override_ship){
            return(<strong>{window.sitesettings.checkout_info.label_custom_ship}</strong>)
        }

        

        if (Object.keys(saved.shipping).length === 0) {
            return (
                <>
                    <strong>Delivery Preference</strong>
                    <div>{getDeliveryLabel(saved.delivery)}</div>
                </>
            )
        }

        if (Object.keys(saved.shipping).length > 0) {
            return (
                <>
                    <strong>{(saved.delivery === "local_delivery" ? "Local Delivery" : "Shipping")}</strong>
                    <div>{saved.shipping.name}</div>
                    <div>{saved.shipping.email_address}</div>
                    <div>{saved.shipping.phone_number}</div>
                    <div>{saved.shipping.street_address}</div>
                    {(saved.shipping.street_address_2 && <div>{saved.shipping.street_address_2}</div>)}
                    <div>{saved.shipping.city}, {saved.shipping.province} {saved.shipping.country}</div>
                    <div>{saved.shipping.postal_code}</div>
                </>
            )
        }
    }

    const displaySummary = () => {

        return (
            <>
                <BMAlert className="msg" description={<>
                    <div style={{ "textAlign": "left" }}>
                        <Row>
                            <Col xs={24} md={12} lg={6}>
                                <strong>Contact Information</strong>
                                <div>{(session.user.first_name) ? session.user.first_name : saved.contact.name} {(session.user.last_name) ? session.user.last_name : ""}</div>
                                <div>{(session.user.email_address) ? session.user.email_address : saved.contact.email}</div>
                                <div>{(session.user.phone_number) ? <PhoneNumberFormatter display country={session.user.country} value={session.user.phone_number} /> : saved.contact.phone}</div>
                                <div className="shim"></div>
                                <div className="shim"></div>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                {drawDeliveryPreference()}
                                <div className="shim"></div>
                                <div className="shim"></div>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <strong>Contact Preference</strong>
                                <div>{ucFirst(saved.contact.preferred_communication)}</div>
                                <div className="shim"></div>
                                <div className="shim"></div>
                            </Col>
                            <Col xs={24} md={12} lg={6}>
                                <strong>Payment Method</strong>
                                <div>{getPaymentLabel(saved.payment)}</div>
                            </Col>
                        </Row>

                    </div>
                </>} type="success" />
            </>
        )
    }

    const displaySubmitButton = () => {

        if (!saved.payment) {
            return (
                <>
                    <Button block onClick={() => history.push("/checkout/payment")} danger>Choose payment type first</Button>
                </>
            )
        }

        switch (saved.payment) {
            case "clearent":
                return <div style={{ "height": "30px" }}>&nbsp;</div>
            case "global":
                return displayGlobal();

            case "paypal":
                return displayPaypalButton();
            default:
                return (<Button disabled={submitLoading} loading={submitLoading} onClick={() => submitLarge()} block type="primary">Submit Order</Button>);
        }
    }

    const displayClearent = () => {
        if (saved.payment === "clearent") {
            return (<ClearentPaymentsForm amount={session.cart.summary.total} />)
        }
    }

    const displayGlobal = () => {

        if (saved.payment === "global") {
            return (<GlobalPaymentsButton amount={session.cart.summary.total} />)
        }

    }

    const displayCart = () => {

        const columns = [
            {
                "key": 0,
                "title": "Image",
                "dataIndex": "name"
            },
            {
                "key": 0,
                "title": "Title",
                "dataIndex": "title",
                render: (item) => {

                    if (item.cart_info?.cart_notes?.gift_card_info?.recipient_email) {
                        return (
                            <>

                                <div>{item.title_info.title} <span>{item.title_info.subtitle}</span></div>
                                <Space wrap>
                                    <div><small><div style={{ "opacity": "0.5", "display": "inline-block" }}>From: &nbsp;</div><em>{item.cart_info.cart_notes.gift_card_info.from}</em></small></div>
                                    <div><small><div style={{ "opacity": "0.5", "display": "inline-block" }}>To: &nbsp;</div><em>{item.cart_info.cart_notes.gift_card_info.to} - {item.cart_info.cart_notes.gift_card_info.recipient_email}</em></small></div>
                                </Space>

                                <div><small><em>"{item.cart_info.cart_notes.gift_card_info.message}"</em></small></div>
                            </>
                        )
                    }


                    return ((<div>{item.title_info.title} <span>{item.title_info.subtitle}</span></div>))
                }
            },
            {
                "key": 0,
                "title": "Binding",
                "dataIndex": "binding",
                "responsive": ["sm"]
            },
            {
                "key": 0,
                "title": "Quantity",
                "dataIndex": "quantity",
                "responsive": ["sm"]
            },

            {
                "key": 0,
                "title": "Price",
                "dataIndex": "price"
            },

        ];

        const imageFallback = (i) => {
            i.target.src = "https://cdn1.bookmanager.com/i/nocover.png"
            i.target.className = "light-cart";
        }

        const data = session.cart.items.map((item, i) => {

            let { giftcard_price = false } = item.cart_info.cart_notes;

            return {
                "key": item.title_info.eisbn + "" + item.title_info.available.code + i,
                "name": (<div><img onError={(i) => imageFallback(i)} alt={getItemAlt(item.title_info)} className="shadow-cart" style={{ "maxHeight": "50px" }} src={"https://cdn1.bookmanager.com/i/m?b=" + item.title_info.eisbn + "&cb=" + item.title_info.cover_image_cache} /></div>),
                "title": item,
                "binding": (<div><span>{item.title_info.binding}</span></div>),
                "quantity": (<div><span>{item.cart_info.quantity}</span></div>),
                "price": (<><div>${(giftcard_price) ? giftcard_price : item.title_info.available.row_price}</div>
                    <conditional.true value={(item.title_info.available.discount)}>
                        <small><em style={{ "color": "#e70d3d" }}>{item.title_info.available.discount}%&nbsp;off&nbsp;${item.title_info.available.price}</em></small>
                    </conditional.true>
                </>)
            }
        })


        return (
            <>
                <Table className="hide" ghost={true} pagination={false} dataSource={data} columns={columns}></Table>
            </>
        )

    }

    const displayError = (_obj) => {
        if (Array.isArray(_obj) || typeof _obj === "object") {
            return Object.keys(_obj).map((item, index) => {
                return (<div key={index} style={{ "display": "block" }}>{item} : {_obj[item]}</div>)
            })
        } else {
            return (<div style={{ "display": "block" }}>{_obj}</div>);
        }
    }

    return (
        <>
            <Modal modalRender={() =>
                <div style={{ "textAlign": "center", "color": "#fff", "fontSize": "16px" }}>
                    Processing...
                </div>} open={waitingToProcess} centered>
            </Modal>

            <Layout className="responsiveSider">
                <Layout.Content style={{ "width": "auto" }}>
                    <Card className="hideCard">
                        <div aria-hidden><Divider orientation="left" >Summary</Divider></div>
                        {displaySummary()}
                        <div className="hide">
                            <div aria-hidden><Divider orientation="left" >Line Items</Divider></div>
                            {displayCart()}
                        </div>
                    </Card>
                </Layout.Content>
                <Layout.Sider width="none" className="responsiveSummary" theme="light">
                    <CartSummary>
                        <br />
                        {(errorMSG &&
                            <>
                                <Alert className="msg" description={<><span style={{ "textAlign": "center", "width": "100%", "display": "block" }}>{displayError(errorMSG)}</span></>} type="error" /><br />
                            </>
                        )}

                        { }
                        {displaySubmitButton()}
                    </CartSummary>
                    {displayClearent()}

                </Layout.Sider>
            </Layout>
        </>
    )
}